<!-- src/views/MainPage.vue -->
<template>
  <div class="d-flex" id="wrapper" ref="wrapper">
    <!-- 侧边栏 -->
    <div class="bg-light border-right" id="sidebar-wrapper">
      <div class="sidebar-heading">Fuyao Client</div>
      <div class="list-group list-group-flush">
        <router-link to="/main/clients" class="list-group-item list-group-item-action bg-light">客户管理</router-link>
        <router-link to="/main/media-library" class="list-group-item list-group-item-action bg-light">媒体库</router-link>
        <router-link to="/main/social-media-apis" class="list-group-item list-group-item-action bg-light">
          社交媒体 API
        </router-link>
        <router-link to="/main/chat-integration" class="list-group-item list-group-item-action bg-light">
          聊天软件集成
        </router-link>
      </div>
    </div>

    <!-- 页面内容 -->
    <div id="page-content-wrapper">
      <!-- 顶部导航栏 -->
      <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
        <button class="btn btn-primary" @click="toggleMenu">切换菜单</button>
      </nav>

      <!-- 子路由内容 -->
      <div class="container-fluid mt-4">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  methods: {
    toggleMenu() {
      this.$refs.wrapper.classList.toggle('toggled'); // 使用 $refs 进行 DOM 操作
    },
  },
};
</script>

<style scoped>
/* 侧边栏和内容区域的样式 */
#wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar-wrapper {
  min-width: 250px;
  max-width: 250px;
}

#page-content-wrapper {
  width: 100%;
  padding: 20px;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: -250px;
}

@media (max-width: 768px) {
  #sidebar-wrapper {
    margin-left: -250px;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }
}
</style>
