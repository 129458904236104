<!-- src/views/AdminLogin.vue -->
<template>
  <div class="container mt-5">
    <h2 class="text-center">管理员登录</h2>
    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="card p-4">
          <form @submit.prevent="login">
            <div class="mb-3">
              <label for="username" class="form-label">用户名</label>
              <input type="text" class="form-control" id="username" v-model="username" required />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">密码</label>
              <input type="password" class="form-control" id="password" v-model="password" required />
            </div>
            <button type="submit" class="btn btn-primary w-100">登录</button>
          </form>
          <div v-if="errorMessage" class="alert alert-danger mt-3">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminLogin',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    login() {
      this.$axios
        .post('/admin/login', {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.$store.commit('setAdmin', response.data.data);
            this.$router.push('/main');
          } else {
            this.errorMessage = response.data.message;
          }
        })
        .catch((error) => {
          this.errorMessage = '登录失败，请检查用户名和密码。';
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
/* 可根据需要添加样式 */
</style>
