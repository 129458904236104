// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'; // 导入 Vuex store
import AdminLogin from '../views/AdminLogin.vue';
import MainPage from '../views/MainPage.vue';
import ClientManagement from '../views/ClientManagement.vue';
import ClientDetails from '../views/ClientDetails.vue';
import MediaLibrary from '../views/MediaLibrary.vue';
import SocialMediaAPIs from '../views/SocialMediaAPIs.vue';
import ChatIntegration from '../views/ChatIntegration.vue';

const routes = [
  {
    path: '/',
    redirect: '/admin/login',
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
  },
  {
    path: '/main',
    component: MainPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/main/clients', // 使用绝对路径
      },
      {
        path: 'clients',
        name: 'ClientManagement',
        component: ClientManagement,
      },
      {
        path: 'clients/:id',
        name: 'ClientDetails',
        component: ClientDetails,
      },
      {
        path: 'media-library',
        name: 'MediaLibrary',
        component: MediaLibrary,
      },
      {
        path: 'social-media-apis',
        name: 'SocialMediaAPIs',
        component: SocialMediaAPIs,
      },
      {
        path: 'chat-integration',
        name: 'ChatIntegration',
        component: ChatIntegration,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 导航守卫，确保需要认证的页面只能被登录的用户访问
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.state.admin !== null; // 使用 store 访问登录状态
  if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
    next('/admin/login');
  } else {
    next();
  }
});

export default router;
