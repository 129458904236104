<!-- src/views/MediaLibrary.vue -->
<template>
    <div>
      <h2>媒体库</h2>
      <button class="btn btn-primary mb-3" @click="openUploadModal">上传媒体文件</button>
  
      <!-- 媒体文件列表 -->
      <div class="row">
        <div class="col-md-3 mb-3" v-for="(file, index) in mediaFiles" :key="index">
          <div class="card">
            <img
              v-if="isImage(file.url)"
              :src="file.url"
              class="card-img-top"
              alt="Media"
              style="height: 150px; object-fit: cover;"
            />
            <div class="card-body">
              <p class="card-text">{{ file.name }}</p>
              <a :href="file.url" class="btn btn-primary btn-sm" target="_blank">查看</a>
            </div>
          </div>
        </div>
      </div>
  
      <!-- 上传媒体文件的模态框 -->
      <div class="modal fade" id="uploadModal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <form @submit.prevent="uploadMedia">
              <div class="modal-header">
                <h5 class="modal-title">上传媒体文件</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label class="form-label">选择文件</label>
                  <input type="file" class="form-control" @change="handleFileSelect" multiple />
                </div>
                <div class="mb-3">
                  <label class="form-label">新建文件夹（可选）</label>
                  <input type="text" class="form-control" v-model="newFolderName" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                <button type="submit" class="btn btn-primary">上传</button>
              </div>
            </form>
          </div>
        </div>
      </div>
  
      <!-- 错误提示 -->
      <div v-if="errorMessage" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'MediaLibrary',
    data() {
      return {
        mediaFiles: [],
        selectedFiles: [],
        newFolderName: '',
        errorMessage: '',
      };
    },
    mounted() {
      this.fetchMediaFiles();
    },
    methods: {
      fetchMediaFiles() {
        axios
          .get('https://shawnadmin.fuyaosports.com/media/files', { withCredentials: true })
          .then((response) => {
            if (response.data.status === 'success') {
              this.mediaFiles = response.data.data;
            } else {
              this.errorMessage = response.data.message;
            }
          })
          .catch((error) => {
            this.errorMessage = '获取媒体文件失败。';
            console.error(error);
          });
      },
      openUploadModal() {
        this.selectedFiles = [];
        this.newFolderName = '';
        const modal = new window.bootstrap.Modal(document.getElementById('uploadModal'));
        modal.show();
      },
      handleFileSelect(event) {
        this.selectedFiles = event.target.files;
      },
      uploadMedia() {
        const formData = new FormData();
        for (let i = 0; i < this.selectedFiles.length; i++) {
          formData.append('files[]', this.selectedFiles[i]);
        }
        formData.append('folder', this.newFolderName);
  
        axios
          .post('https://shawnadmin.fuyaosports.com/media/upload', formData, {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.data.status === 'success') {
              this.fetchMediaFiles();
              const modalElement = document.getElementById('uploadModal');
              const modal = window.bootstrap.Modal.getInstance(modalElement);
              modal.hide();
            } else {
              this.errorMessage = response.data.message;
            }
          })
          .catch((error) => {
            this.errorMessage = '上传媒体文件失败。';
            console.error(error);
          });
      },
      isImage(url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
      },
    },
  };
  </script>
  
  <style scoped>
  /* 可根据需要添加样式 */
  </style>
  