// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

// 引入 Bootstrap 的 CSS 和 JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// 全局配置 Axios
axios.defaults.baseURL = 'https://shawnadmin.fuyaosports.com';
axios.defaults.withCredentials = true; // 允许跨域携带凭证

// 创建 Vue 应用
const app = createApp(App);

// 将 Axios 挂载到全局属性，方便在组件中使用
app.config.globalProperties.$axios = axios;

// 使用插件
app.use(store);
app.use(router);

// 挂载应用
app.mount('#app');
