// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    admin: null, // 存储管理员信息
  },
  mutations: {
    setAdmin(state, adminData) {
      state.admin = adminData;
    },
  },
  actions: {},
  modules: {},
});
