<!-- src/views/ClientDetails.vue -->
<template>
    <div>
      <h2>客户详情</h2>
      <div v-if="client">
        <p><strong>姓名：</strong> {{ client.name }}</p>
        <p><strong>国家：</strong> {{ client.country }}</p>
        <p><strong>邮箱：</strong> {{ client.email }}</p>
        <p><strong>电话：</strong> {{ client.phone }}</p>
        <p><strong>WhatsApp：</strong> {{ client.whatsapp }}</p>
        <p><strong>进度：</strong> {{ client.progress }}</p>
        <p><strong>备注：</strong> {{ client.remark }}</p>
  
        <!-- 附件列表 -->
        <h4>附件</h4>
        <div class="row">
          <div class="col-md-3 mb-3" v-for="(file, index) in client.attachments" :key="index">
            <div class="card">
              <img
                v-if="isImage(file.url)"
                :src="file.url"
                class="card-img-top"
                alt="Attachment"
                style="height: 150px; object-fit: cover;"
              />
              <div class="card-body">
                <p class="card-text">{{ file.name }}</p>
                <a :href="file.url" class="btn btn-primary btn-sm" target="_blank">查看</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>加载中...</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'ClientDetails',
    data() {
      return {
        client: null,
        errorMessage: '',
      };
    },
    mounted() {
      this.fetchClientDetails();
    },
    methods: {
      fetchClientDetails() {
        const clientId = this.$route.params.id;
        axios
          .get(`https://shawnadmin.fuyaosports.com/client/${clientId}`, { withCredentials: true })
          .then((response) => {
            if (response.data.status === 'success') {
              this.client = response.data.data;
            } else {
              this.errorMessage = response.data.message;
            }
          })
          .catch((error) => {
            this.errorMessage = '获取客户详情失败。';
            console.error(error);
          });
      },
      isImage(url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
      },
    },
  };
  </script>
  
  <style scoped>
  /* 可根据需要添加样式 */
  </style>
  