<!-- src/views/ClientManagement.vue -->
<template>
  <div>
    <h2>客户管理</h2>

    <!-- 搜索和分类 -->
    <div class="row mb-3">
      <div class="col-md-4">
        <input
          type="text"
          class="form-control"
          placeholder="搜索姓名、电话、邮箱"
          v-model="searchQuery"
          @input="fetchClients"
        />
      </div>
      <div class="col-md-3">
        <select class="form-select" v-model="selectedCountry" @change="fetchClients">
          <option value="">所有国家</option>
          <option v-for="country in countries" :key="country" :value="country">{{ country }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <select class="form-select" v-model="selectedProgress" @change="fetchClients">
          <option value="">所有进度</option>
          <option v-for="status in progressStatuses" :key="status" :value="status">{{ status }}</option>
        </select>
      </div>
      <div class="col-md-2 text-end">
        <button class="btn btn-primary" @click="openAddClientModal">添加客户</button>
      </div>
    </div>

    <!-- 客户列表 -->
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>姓名</th>
          <th>国家</th>
          <th>邮箱</th>
          <th>电话</th>
          <th>WhatsApp</th>
          <th>进度</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="client in clients" :key="client.id">
          <td>{{ client.name }}</td>
          <td>{{ client.country }}</td>
          <td>{{ client.email }}</td>
          <td>{{ client.phone }}</td>
          <td>{{ client.whatsapp }}</td>
          <td>{{ client.progress }}</td>
          <td>
            <router-link :to="`/main/clients/${client.id}`" class="btn btn-sm btn-info">查看详情</router-link>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- 分页 -->
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">上一页</a>
        </li>
        <li
          class="page-item"
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
        >
          <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">下一页</a>
        </li>
      </ul>
    </nav>

    <!-- 添加客户的模态框 -->
    <div class="modal fade" id="addClientModal" tabindex="-1" ref="addClientModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addClient">
            <div class="modal-header">
              <h5 class="modal-title">添加客户</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
              <!-- 表单字段 -->
              <div class="mb-3">
                <label class="form-label">姓名</label>
                <input type="text" class="form-control" v-model="newClient.name" required />
              </div>
              <div class="mb-3">
                <label class="form-label">国家</label>
                <input type="text" class="form-control" v-model="newClient.country" />
              </div>
              <div class="mb-3">
                <label class="form-label">邮箱</label>
                <input type="email" class="form-control" v-model="newClient.email" />
              </div>
              <div class="mb-3">
                <label class="form-label">电话</label>
                <input type="text" class="form-control" v-model="newClient.phone" />
              </div>
              <div class="mb-3">
                <label class="form-label">WhatsApp</label>
                <input type="text" class="form-control" v-model="newClient.whatsapp" />
              </div>
              <div class="mb-3">
                <label class="form-label">进度</label>
                <select class="form-select" v-model="newClient.progress">
                  <option value="">请选择进度</option>
                  <option v-for="status in progressStatuses" :key="status" :value="status">{{ status }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">备注</label>
                <textarea class="form-control" v-model="newClient.remark"></textarea>
              </div>
              <!-- 上传附件 -->
              <div class="mb-3">
                <label class="form-label">附件</label>
                <input type="file" class="form-control" @change="handleFileUpload" multiple />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
              <button type="submit" class="btn btn-primary">保存</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- 错误提示 -->
    <div v-if="errorMessage" class="alert alert-danger mt-3">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'; // 导入 Bootstrap 的 Modal 组件

export default {
  name: 'ClientManagement',
  data() {
    return {
      clients: [],
      searchQuery: '',
      selectedCountry: '',
      selectedProgress: '',
      countries: [],
      progressStatuses: ['新客户', '跟进中', '已成交', '失效'],
      currentPage: 1,
      totalPages: 1,
      newClient: {
        name: '',
        country: '',
        email: '',
        phone: '',
        whatsapp: '',
        progress: '',
        remark: '',
      },
      errorMessage: '',
      attachments: [],
      modalInstance: null, // 模态框实例
    };
  },
  mounted() {
    this.fetchClients();
  },
  methods: {
    fetchClients() {
      this.$axios
        .get('/clients', {
          params: {
            search: this.searchQuery,
            country: this.selectedCountry,
            progress: this.selectedProgress,
            page: this.currentPage,
            per_page: 10,
          },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.clients = response.data.data;
            this.totalPages = Math.ceil(response.data.total / 10);
            this.extractCountries();
          } else {
            this.errorMessage = response.data.message;
          }
        })
        .catch((error) => {
          this.errorMessage = '获取客户列表失败。';
          console.error(error);
        });
    },
    extractCountries() {
      const countrySet = new Set(this.clients.map((client) => client.country));
      this.countries = Array.from(countrySet).filter((country) => country);
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchClients();
      }
    },
    openAddClientModal() {
      // 重置新客户数据
      this.newClient = {
        name: '',
        country: '',
        email: '',
        phone: '',
        whatsapp: '',
        progress: '',
        remark: '',
      };
      this.attachments = [];

      // 使用 $refs 获取模态框元素
      if (!this.modalInstance) {
        this.modalInstance = new Modal(this.$refs.addClientModal);
      }
      this.modalInstance.show();
    },
    handleFileUpload(event) {
      this.attachments = Array.from(event.target.files);
    },
    addClient() {
      const formData = new FormData();
      for (const key in this.newClient) {
        formData.append(key, this.newClient[key]);
      }
      // 添加附件
      for (let i = 0; i < this.attachments.length; i++) {
        formData.append('attachments[]', this.attachments[i]);
      }

      this.$axios
        .post('/client/add', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.fetchClients();
            if (this.modalInstance) {
              this.modalInstance.hide();
            }
          } else {
            this.errorMessage = response.data.message;
          }
        })
        .catch((error) => {
          this.errorMessage = '添加客户失败。';
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
/* 可根据需要添加样式 */
</style>
